import { Fragment, memo } from 'react';
import { SideNav } from '~/pages/admin/components/SideNav';
import Filtering from '~/pages/admin/components/Filtering';
import UserTable from '~/pages/admin/components/UserTable';
import { adminStore } from '~/pages/admin/components/adminStore';
import NotificationSign from '~/pages/admin/components/NotificationSign';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
import { css } from '@emotion/react';
import { createMobileAndIPadCss } from '~/css/createMobileAndIPadCss';
import { LoginView } from '~/modules/auth/components/UserSubscriptionCheckAsFullscreen';
import { AgentProduct } from '~/configs/AgentProduct';
import { Preset_EntirePage } from '~/pages/heineken_template/_preset/preset_EntirePage';
import { apirc } from '~/configs/apirc';
import { Permission } from '~/pages/admin/components/Permission';
import { useProxy } from 'valtio/utils';
const AdminDashboardNextPage = memo(function AdminDashboardNextPage() {
    useProxy(adminStore);
    apirc.admin.getPermissions.useSWR();
    return (<Fragment>
      <Filtering />
      {/* {currentRoleType === 'notifier' ? null : <UserTable />} */}
      <Permission oneOfRoleTypes={['admin', 'readOnly']}>
        <UserTable />
      </Permission>
      <NotificationSign />
    </Fragment>);
});
const templateProps = new TemplateProps();
if (fr_agents.is['futuresai@admin']) {
    templateProps.useGlobalHooks = function () {
        // noop
    };
    templateProps.hooks.add(fr_me.useInstall);
    templateProps.permissions.pageview = fr_me.toTemplate.permissions.hasPermission(AgentProduct['futuresai@admin']);
    templateProps.layout.Charting = undefined;
    templateProps.layout.login = (<Preset_EntirePage>
      <LoginView />
    </Preset_EntirePage>);
    templateProps.layout.Col1 = SideNav;
    templateProps.layout.Col2 = AdminDashboardNextPage;
    templateProps.layout.Drawer1 = undefined;
    templateProps.layout.Drawer2 = undefined;
    templateProps.layout.cssset = css `
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 80px 1fr;

    grid-template-areas:
      'Col1 Col2'
      'Col1 Col2'
      'Col1 Col2';
    overflow: auto;

    ${createMobileAndIPadCss(css `
      place-content: flex-start;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 80px 1fr;
      grid-template-areas:
        'Col1 Col2'
        'Col1 Col2'
        'Col1 Col2';
    `)}
  `;
}
export default templateProps.NextPage;
