/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from 'react';
import { Pagination } from '@mantine/core';
import { css } from '@emotion/react';
import { fontWeight500 } from '~/css/font';
import { adminStore } from '~/pages/admin/components/adminStore';
import UserModal from '~/pages/admin/components/UserModal';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { apirc } from '~/configs/apirc';
import { ListOutline } from '~/pages/admin/components/ListOutline';
import { UserListRows } from '~/pages/admin/components/UserListRows';
import { useProxy } from 'valtio/utils';
import { UserListHeader } from '~/pages/admin/components/UserListHeader';
import { adminConfig } from '~/pages/admin/components/adminConfig';
const UserTable = memo(function UserTable() {
    useProxy(adminStore);
    // const [clipboardText, setClipboardText] = useState('')
    // const getArrow = (key: SortBy) => {
    //   if (key === adminStore.sortBy) {
    //     return (
    //       <span
    //         css={css`
    //           color: red;
    //           font-size: 20px;
    //         `}
    //       >
    //         {adminStore.sortOrder === 'asc' ? <MdArrowDropUp /> : <MdArrowDropDown />}
    //       </span>
    //     )
    //   } else return null
    // }
    useEffect(() => {
        if (adminStore.selectedUserId) {
            apirc.admin.getUser.fetch();
            apirc.admin.getUserSubscriptionHistory.fetch();
        }
    }, [adminStore.selectedUserId, adminStore.userScopeProduct]);
    useEffect(() => {
        if (adminStore.selectedAgent && adminStore.selectedProduct) {
            apirc.admin.getUsers.fetch();
        }
    }, [adminStore.selectedAgent, adminStore.selectedProduct]);
    // useEffect(() => {
    //   if (clipboardText)
    //     setTimeout(() => {
    //       setClipboardText('')
    //     }, 5000)
    // }, [clipboardText])
    // const handleChangeSortBy = (key: SortBy) => {
    //   if (adminStore.sortBy !== key) {
    //     adminStore['sortBy'] = key
    //     adminStore['sortOrder'] = 'desc'
    //   } else {
    //     adminStore['sortOrder'] = adminStore.sortOrder === 'desc' ? 'asc' : 'desc'
    //   }
    //   apirc.admin.getUsers.fetch()
    // }
    // const tableHeader = (
    //   <tr>
    //     <th
    //       css={sortableColumnCss}
    //       onClick={() => handleChangeSortBy('uid')}
    //     >
    //       UID {getArrow('uid')}
    //     </th>
    //     <th
    //       css={sortableColumnCss}
    //       onClick={() => handleChangeSortBy('code')}
    //     >
    //       會員代碼 {getArrow('code')}
    //     </th>
    //     <th
    //       css={sortableColumnCss}
    //       onClick={() => handleChangeSortBy('name')}
    //     >
    //       姓名
    //       {getArrow('name')}
    //     </th>
    //     <th
    //       css={sortableColumnCss}
    //       onClick={() => handleChangeSortBy('email')}
    //     >
    //       電子信箱 {getArrow('email')}
    //     </th>
    //     <th>開始日期</th>
    //     <th
    //       css={sortableColumnCss}
    //       onClick={() => handleChangeSortBy('expiredAt')}
    //     >
    //       到期日期 {getArrow('expiredAt')}
    //     </th>
    //     <th css={headerColumnCss}>剩餘天數</th>
    //     <th
    //       css={sortableColumnCss}
    //       onClick={() => handleChangeSortBy('createdAt')}
    //     >
    //       註冊日期{getArrow('createdAt')}
    //     </th>
    //     <th>訂閱類型</th>
    //   </tr>
    // )
    // const rows = adminStore.users.map(user => {
    //   const lastValidSubscription = user.subscriptions?.reduce((prev, current) =>
    //     prev.expiredAt > current.expiredAt ? prev : current,
    //   ) // 多筆訂閱資料 選取訂閱到期日最遠的
    //   const expiredDate = dayAPI(lastValidSubscription?.expiredAt)
    //   const remainingDays = expiredDate.diff(dayAPI(), 'day', true)
    //   const end = dayAPI(lastValidSubscription?.expiredAt).format('YYYY/MM/DD HH:mm')
    //   const start = dayAPI(lastValidSubscription?.startedAt).format('YYYY/MM/DD HH:mm')
    //   const created = dayAPI(user.createdAt).format('YYYY/MM/DD HH:mm')
    //   return (
    //     <tr
    //       css={css`
    //         :hover {
    //           background: ${globalGrey.g200};
    //           cursor: pointer;
    //         }
    //       `}
    //       key={user.uid}
    //       onClick={() => {
    //         adminStore.selectedUserId = user.uid
    //         adminStore.modalOpen = true
    //         adminStore.userScopeProduct = adminStore.selectedProduct
    //         const selectedProductObj = adminStore.admin?.roles
    //           .find(role => role.agentName === adminStore.selectedAgent)
    //           ?.products.find(p => p.name === adminStore.selectedProduct)
    //         if (selectedProductObj)
    //           adminStore.readonlyMode = selectedProductObj.roleType === 'readOnly'
    //       }}
    //     >
    //       <td
    //         css={css`
    //           cursor: pointer;
    //           overflow: hidden;
    //           text-overflow: ellipsis;
    //         `}
    //         onClick={() => {
    //           setClipboardText(user.uid)
    //           navigator.clipboard.writeText(user.uid)
    //         }}
    //       >
    //         <Tooltip label={user.uid}>
    //           <p>{user.uid.slice(0, 10)}</p>
    //         </Tooltip>
    //       </td>
    //       <td>{user.code}</td>
    //       <td>{user.name}</td>
    //       <td>{user.email}</td>
    //       <td>{start}</td>
    //       <td>{end}</td>
    //       <td
    //         css={css`
    //           color: ${remainingDays > 0 ? 'black' : globalGrey.g400};
    //         `}
    //       >
    //         {(remainingDays > 0 ? '' : '過期') + `${Math.abs(Math.round(remainingDays))}天`}
    //       </td>
    //       <td>{created}</td>
    //       <td>{lastValidSubscription?.status}</td>
    //     </tr>
    //   )
    // })
    return (<div css={css `
        padding: 12px;
      `}>
      {/* {clipboardText ? (
          <Alert
            css={css`
              position: fixed;
              right: 12px;
              z-index: 2;
            `}
            icon={<MdAlarm />}
            title={`已複製 ${clipboardText} 到剪貼簿`}
            color='orange'
            variant='filled'
            withCloseButton
            onClose={() => setClipboardText('')}
          >
            <></>
          </Alert>
        ) : null} */}
      <div css={flex.h.crossCenter}>
        <p css={fontWeight500}>總計 {adminStore.total} 筆</p>
        <Pagination css={css `
            margin: 16px 0 16px 16px;
          `} disabled={adminStore.totalPage === 0} withEdges value={adminStore.page} onChange={value => {
            adminStore.page = value;
            apirc.admin.getUsers.fetch();
        }} total={adminStore.totalPage === 0 ? 1 : adminStore.totalPage}/>
      </div>
      <div css={css `
          max-width: calc(100vw - 80px - 32px);
          height: 80vh;
          overflow: auto;
        `}>
        <ListOutline>
          {/* <caption>Some elements from periodic table</caption> */}
          {/* <tbody>{rows}</tbody> */}
          {/* <thead>{tableHeader}</thead> */}
          <thead>
            <UserListHeader columns={adminConfig.userTableColumns.dashboard}/>
          </thead>
          <tbody>
            <UserListRows users={adminStore.users} columns={adminConfig.userTableColumns.dashboard} onRowClick={user => {
            adminStore.selectedUserId = user.uid;
            adminStore.modalOpen = true;
            adminStore.userScopeProduct = adminStore.selectedProduct;
            const selectedProductObj = adminStore.admin?.roles
                .find(role => role.agentName === adminStore.selectedAgent)
                ?.products.find(p => p.name === adminStore.selectedProduct);
            if (selectedProductObj) {
                adminStore.readonlyMode = selectedProductObj.roleType === 'readOnly';
            }
        }}/>
          </tbody>
          {/* <tfoot>{ths}</tfoot> */}
        </ListOutline>
        <UserModal />
      </div>
    </div>);
});
// const headerColumnCss = css`
//   min-width: 120px;
//   max-width: 240px;
// `
// const sortableColumnCss = css`
//   cursor: pointer;
//   ${headerColumnCss};
// `
export default UserTable;
